import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import './App.css';

function App() {
  return (
      <Container fluid={true}>
        <Row noGutters={true} className="App-header">
          <Col>
          <img src="microbis logo-med.jpg" classname="App-logo" alt="logo" />
          </Col>
        </Row>
        <Row className="App-middle">
          <Col xs="1" ></Col>
          <Col>
          <img src="contact.jpg" alt="contact" />
          </Col>
        </Row>
        <Row className="App-footer">
        <Col xs="1" > </Col>
          <Col> </Col>
        </Row>
      </Container>
  );
}

export default App;

/*
    <div ClassName = "wrapper"> 
      <header className="header">
        <img src="microbis logo-med.jpg" classname="App-logo" alt="logo" />
      </header>
      <aside  classname="aside-1" >
      </aside>
      <main  classname="main" >
      <img src="contact.jpg" alt="contact" />
      </main>
      <footer classname="footer">
      <img src="contact.jpg" alt="contact" /> 
      </footer>
    </div>
    */
